@import 'styles/variables.scss';
.search-box {
  width: 300px;
  padding: 12px 12px 24px;
  border-radius: $border-radius;
  background: rgba(0, 0, 0, 0.5);
  font-weight: bold;
  .date,
  .keyword {
    margin-bottom: 15px;
  }
  .nap-detail {
    color: #fff;
    font-size: 1.2rem;
    .nap-detail-title {
      font-size: 1.6rem;
      text-align: center;
      margin: 24px 0 12px;
    }
    .features {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
    }
    .feature-item,
    .feature-title,
    .feature-body {
      margin: 0;
    }
    .feature-value {
      font-weight: bold;
      font-size: 2.4rem;
    }
  }
  .map-button {
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid $col-gray6;
  }
}
