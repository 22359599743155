@import 'styles/variables.scss';

.map-button-container {
  &:hover {
    text-decoration: none;
  }
  .map-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    font-weight: bold;
    background: #539fd6;
    border-radius: 4px;
    &:hover {
      opacity: 0.75;
    }
    .map-button-text {
      margin: 0;
      color: $col-white;
      font-size: 1.6rem;
    }
  }
}
