@import 'styles/variables.scss';
.search-box {
  width: 100%;
  background: #fff;
  border: 1px $item-border-color solid;
  border-radius: 4px;
  overflow: hidden;

  &.is-result {
    border-radius: 0;
    border-left: none;
    border-right: none;
    border-top: none;
    .location {
      border-bottom: 1px $item-border-color solid;
    }
  }

  &.is-detail {
    border-color: $item-border-color;
    border-radius: 4px;
    .location {
      border-color: $item-border-color;
    }
  }
}
.location {
  border-bottom: 1px $item-border-color solid;
  border-left: none;
  border-right: none;
}
.date {
  border-bottom: 1px $item-border-color solid;
  border-left: none;
  border-right: none;
}
.buttons {
  display: flex;
}
.search-button {
  width: 50%;
  padding: 5px;
  &.is-no-location {
    width: 100%;
  }
}
.map-button {
  width: 50%;
  padding: 5px;
}
