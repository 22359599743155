@import 'styles/variables.scss';
.keyvisual {
  position: relative;
  width: 100%;
  height: 470px;
  margin: 0 auto;
  .keyvisual-main {
    position: relative;
    z-index: 2;
  }
  .lead-text {
    position: absolute;
    top: 180px;
    left: 350px;
    color: #fff;
    .catchcopy {
      font-size: 3.2rem;
      font-weight: bold;
      line-height: 1;
      margin-bottom: 24px;
    }
    .caption {
      font-size: 1.6rem;
      font-weight: bold;
    }
  }
  .left-box {
    position: absolute;
    top: 80px;
    left: 0;
  }

  .sales-text {
    color: $col-gray1;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    text-decoration: underline;
  }

  .bg-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 470px;
    margin: 0 auto;
  }
}

.recommended-title,
.prefecture-title,
.reservable-title,
.region-title,
.facilities-title,
.purposes-title,
.selections-title {
  font-size: 1.8rem;
  letter-spacing: 0.75px;
  margin-top: 48px;
}
.prefecture-body,
.region-body,
.area-body,
.facilities-body,
.purposes-body,
.selections-body {
  margin-top: 20px;
}

.banner {
  display: flex;
  flex-wrap: wrap;
  .banner-item {
    padding: 12px;
    .img-wrapper {
      border-radius: 4px;
      overflow: hidden;
      &.is-placeholder {
        height: 96px;
        background: $col-gray3;
      }
      .banner-img {
        width: 100%;
        height: 100%;
        max-height: 96px;
      }
      .banner-text {
        font-size: 1.2rem;
        font-weight: bold;
        color: #111;
      }
    }
  }
}

.recommended {
  .recommended-body {
    margin-top: 15px;
  }
  .banner {
    display: flex;
    flex-wrap: wrap;
    .banner-item {
      width: 25%;
    }
    a:nth-child(1),
    a:nth-child(5) {
      padding-left: 0;
    }
    a:nth-child(4),
    a:nth-child(8) {
      padding-right: 0;
    }
  }
}
.prefecture-container {
  .prefecture-body {
  }
  .prefecture-items {
    display: flex;
    justify-content: left;
  }
  .prefecture-item {
    width: $base-width / 8;
    text-align: center;
    border-bottom: 1px $item-border-color solid;
    padding-bottom: 10px;
    color: $col-gray6;
    cursor: pointer;
    margin-right: 10px;
    &.is-current {
      color: $col-base;
      font-weight: bold;
      border-bottom: 2px $col-secondary solid;
    }
  }
  .prefecture-list {
    margin-top: 20px;
  }
  .prefecture-list-items {
    display: flex;
    flex-wrap: wrap;
  }
  .prefecture-list-item {
    margin-bottom: 10px;
    margin-right: 10px;
    &:nth-child(4n) {
      margin-right: 0;
    }
  }
  .prefecture-loading {
    text-align: center;
    padding: 250px 0;
  }
  .more-search-button {
    display: block;
    width: 340px;
    margin: 20px auto 0;
  }
}
.reservable-container {
  height: 327px;
  .reservable-title {
    width: $base-width;
    margin: 48px auto 0;
  }
  .reservable-carousel {
    width: 100%;
    height: 280px;
    margin-top: 20px;
  }
  .reservable-carousel-items {
    display: flex;
    .reservable-carousel-item {
      margin-right: 10px;
    }
  }
  .reservable-blank {
    width: 100%;
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.area-container {
  .area-body {
  }
  .area-lg {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .area-lg-item {
      position: relative;
      width: 505px;
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: $border-radius;
      overflow: hidden;
      &::before {
        content: '';
        width: 100%;
        height: 100%;
        background: $col-bg-gradient;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
      }
      .area-lg-item-img {
        width: 100%;
        height: 100%;
      }
      .area-lg-item-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        margin: 0;
        text-align: center;
        color: #fff;
        font-size: 2.8rem;
        font-weight: bold;
        z-index: 2;
      }
    }
  }
  .area-md {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .area-md-item {
      position: relative;
      width: 333px;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border-radius: $border-radius;
      overflow: hidden;
      &::before {
        content: '';
        width: 100%;
        height: 100%;
        background: $col-bg-gradient;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
      }
      .area-md-item-img {
        width: 100%;
        height: 100%;
      }
      .area-md-item-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        margin: 0;
        text-align: center;
        color: #fff;
        font-size: 2.4rem;
        font-weight: bold;
        z-index: 2;
      }
    }
  }
  .area-sm {
    .area-sm-item {
      display: inline-block;
      padding-right: 10px;
      margin: 0 10px 10px 0;
      &:not(:last-child) {
        border-right: 1px $col-secondary solid;
      }
    }
  }
}
.facilities {
  .facilities-body {
    display: flex;
    flex-wrap: wrap;
  }
  .banner {
    display: flex;
    flex-wrap: wrap;
    .banner-item {
      width: 20%;
      padding: 12px 12px 0;
    }
    a:nth-child(1),
    a:nth-child(6) {
      padding-left: 0;
    }
    a:nth-child(5),
    a:nth-child(10) {
      padding-right: 0;
    }
    .banner-img {
      width: 196px;
      height: 76px;
      object-fit: cover;
    }
  }
}
.region {
  .region-body {
  }
  .region-item {
    display: flex;
  }
  .region-dt {
    width: 180px;
  }
  .region-link {
    display: inline-block;
    padding-right: 15px;
  }
}
.purposes {
  .purposes-body {
  }
}
.selections {
  .selections-caption {
    margin-top: 10px;
  }
  .selections-body {
    display: flex;
    flex-wrap: wrap;
  }
  .selection {
    width: 255px;
    &:hover {
      text-decoration: none;
    }
  }
  .selection-inner {
    width: 247px;
    display: block;
    color: $col-base;
    margin-bottom: 20px;
    &:hover {
      text-decoration: none;
      opacity: 0.7;
    }
  }
  .selection-img-wrap {
    width: 247px;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 10px;
  }
  .selection-img {
    height: 120%;
  }
}
.recruit {
  padding-top: 48px;
}
.campaign {
  display: flex;
  justify-content: space-between;
  a:nth-child(1) {
    margin-left: 0;
  }
  a:nth-child(3) {
    margin-right: 0;
  }
  .campaign-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    background: $col-gray0;
    border-radius: $border-radius;
    margin: 48px 12px;
    padding: 24px 12px;
    min-height: 224px;
    &:hover {
      text-decoration: none;
      opacity: 0.7;
    }
    .campaign-image-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 64px;
      max-width: 64px;
      height: 64px;
      border-radius: 50%;
      overflow: hidden;
      .campaign-image {
        width: 100%;
        height: 100%;
      }
    }
    .campaign-body {
      width: 100%;
      .campaign-title {
        font-size: 1.6rem;
        margin-bottom: 10px;
        color: $col-base;
      }
      .campaign-caption {
        color: $col-gray6;
      }
    }
  }
  .campaign-button {
    display: inline-block;
    margin-top: 10px;
    width: 90px;
  }
}
.region {
  color: $col-base;
  .region-title {
    font-size: 1.8rem;
    padding: 24px 0 12px;
    letter-spacing: 0.75px;
  }
  .region-body {
    .region-item {
      display: flex;
      .region-dt {
        width: 112px;
        font-size: 1.2rem;
        letter-spacing: 0.5px;
        margin: 0;
      }
      .region-dd {
        font-size: 1.2rem;
        letter-spacing: 0.5px;
        margin: 0;
        .region-link {
          display: inline-block;
          padding: 0 12px 0 0;
          margin: 6px 12px 6px 0;
          font-size: 1.2rem;
          line-height: 1;
          letter-spacing: 0.75px;
          text-decoration: underline;
          color: $col-base;
          &:not(:last-child) {
            border-right: 1px $col-secondary solid;
          }
        }
      }
    }
  }
}
.advertisement {
  background: $col-gray1;
  font-weight: bold;
  .advertisement-content {
    max-width: 1044px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    .advertisement-content-banner {
      width: 300px;
      height: 250px;
      margin: 12px;
    }
  }
}

.more-ranking-button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  .more-ranking-button {
    min-width: 300px;
    margin: 0 4px;
  }
}

// <style lang="scss">
// .advertisement iframe {
//   height: 250px !important;
// }
// </style>
