@import 'styles/variables.scss';

.campsite-item {
  z-index: 1;
  position: relative;
  width: 170px;
  height: 160px;
  overflow: hidden;
  border-radius: 5px;
  border: 1px $item-border-color solid;
  .campsite-item-content {
    width: 100%;
    height: auto;
    padding: 5px;
  }
  .campsite-item-link {
    color: $col-base;
  }
  .campsite-item-image-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: top;
    z-index: 1;
    overflow: hidden;
    .campsite-item-image {
      width: 170px;
      height: 95px;
      object-fit: cover;
    }
  }
  .campsite-item-name {
    width: 170px;
    font-size: 1.4rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .campsite-item-location {
    font-size: 1rem;
  }
  .campsite-item-reviews {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .campsite-item-review {
      width: 70px;
    }
    .campsite-item-comment {
      margin-left: 8px;
      font-size: 1rem;
    }
  }
}
