@import 'styles/variables.scss';
.top {
  padding-bottom: 50px;
}
.title {
  position: relative;
  overflow: hidden;
  margin: 0 !important;
  padding: 12px;
  background: $col-gray1;
  border-bottom: 1px solid $item-border-color;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 0.5px;
  &::before {
    content: '';
    position: absolute;
    background: #01c200;
    width: 2px;
    height: 40px;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
  }
}
.keyvisual {
  position: relative;
  width: 100%;
  height: 280px;
  overflow: hidden;
  margin-bottom: 10px;
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background: $col-bg-gradient;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
  }
  .lead-text {
    z-index: 2;
    width: 100%;
    height: 280px;
    position: absolute;
    top: -37px;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .lead-text-inner {
      .main-title {
        font-size: 2rem;
        font-weight: bold;
        color: #fff;
        line-height: 120%;
        text-align: center;
      }
      .caption {
        margin-top: 12px;
        display: block;
        font-size: 1.2rem;
        color: #fff;
        text-align: center;
      }
    }
  }
  .bg-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 280px;
    overflow: hidden;
  }
}
.banner {
  display: flex;
  flex-wrap: wrap;
  .banner-item {
    width: 50%;
    padding: 12px 12px 0;
    .img-wrapper {
      border-radius: 4px;
      overflow: hidden;
      &.is-placeholder {
        height: 81px;
        background: $col-gray3;
      }
      .banner-img {
        width: 100%;
        height: 100%;
        max-height: 96px;
        object-fit: cover;
      }
      .banner-text {
        font-size: 1.2rem;
        font-weight: bold;
        color: #111;
      }
    }
  }
  .banner-item:nth-child(2n) {
    padding-left: 6px;
  }
  .banner-item:nth-child(2n + 1) {
    padding-right: 6px;
  }
}

.search {
  padding: 0 12px;
  .search-box {
    position: relative;
    top: -108px;
    z-index: 2;
    height: 40px;
  }
  .map-button {
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid $col-gray3;
  }
}

.prefecture-container {
  .prefecture-body {
    background: $col-gray1;
    position: relative;
    width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
    .prefecture-items {
      position: relative;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      min-width: 640px;
      padding: 0 12px;
      height: 40px;
      margin-top: 12px;
      margin-right: 4px;
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 40px;
        top: 0;
        left: 0;
        z-index: 0;
        border-bottom: 2px solid $col-primary;
      }
      .prefecture-item {
        position: relative;
        width: 80px;
        height: 40px;
        margin-bottom: -2px;
        color: $col-gray6;
        font-weight: bold;
        line-height: 40px;
        text-align: center;
        margin-right: 4px;
        background: #f2f5f5;
        border-bottom: 2px solid #01c200;
        border-radius: 4px 4px 0 0;
        box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.25);
        z-index: 1;
        &.is-current {
          background: $col-white;
          color: $col-base;
          border-bottom: none;
          z-index: 1;
        }
      }
    }
  }
  .prefucture-loading {
    padding: 0 12px;
  }
  .prefecture-list {
    padding: 0 12px;
    .prefecture-list-items {
      margin-top: 12px;
      .prefecture-list-item {
        margin-top: 12px;
      }
    }
    .more-search-button {
      display: inline-block;
      margin-top: 12px;
      width: 100%;
    }
  }
}
.recommended {
  padding-bottom: 12px;
  .recommended-list {
    overflow: hidden;
    height: auto;
    margin: 0;
    padding: 0;
    li {
      padding: 12px 12px 0;
    }
  }
  .more-search-button {
    display: block;
    padding: 12px 12px 0;
  }
}

.locationList {
  margin-bottom: 25px;
}

.selections {
  .selections-caption {
    font-size: 1.2rem;
    font-style: italic;
    padding: 4px 12px;
    background: #f1f5f5;
  }
  .selection {
    display: flex;
    justify-content: flex-start;
    color: $col-base;
    padding: 12px 12px 0;
    .selection-image-wrap {
      width: 112px;
      height: 64px;
      overflow: hidden;
      border-radius: $border-radius;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 12px;
      .selection-image {
        max-width: none;
        height: 100px;
      }
    }
    .selection-body {
      width: 72%;
    }
  }
}

.facility {
  padding: 0;
  .facilities {
  }
}

.purposes {
  padding: 0;
  .purposes-item {
    padding: 12px;
  }
}

.campaign {
  .campaign-item {
    display: flex;
    flex-direction: column;
    padding: 12px;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: $col-gray0;
    border-radius: $border-radius;
    margin: 12px;
    min-height: 224px;
    &:hover {
      text-decoration: none;
      opacity: 0.7;
    }
  }
  .campaign-image-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 72px;
    max-width: 72px;
    height: 72px;
    border-radius: 50%;
    overflow: hidden;
  }
  .campaign-image {
    width: 100%;
    height: 100%;
  }
  .campaign-title {
    font-size: 1.6rem;
    margin: 12px 0 6px;
    color: $col-base;
  }
  .campaign-caption {
    color: $col-gray6;
  }
  .campaign-button {
    display: inline-block;
    margin-top: 10px;
    width: 90px;
  }
}

.publicity {
  display: flex;
  flex-direction: column;
  padding: 12px;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: $col-gray0;
  border-radius: $border-radius;
  margin: 12px;
  min-height: 224px;
  .publicity-image-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 72px;
    max-width: 72px;
    height: 72px;
    border-radius: 50%;
    overflow: hidden;
  }
  .publicity-image {
    width: 100%;
    height: 100%;
  }
  .publicity-title {
    font-size: 1.6rem;
    margin: 12px 0 6px;
    color: $col-base;
  }
  .publicity-caption {
    color: $col-gray6;
  }
  .publicity-button {
    display: inline-block;
    margin-top: 10px;
    width: 100%;
  }
}
.location-list {
  margin-bottom: 50px;
}

.recruit {
  padding: 12px;
}

.more-ranking-button-container {
  width: 100%;
  padding: 12px;
  .more-ranking-button {
    display: block;
    margin: 0 0 12px;
  }
}

.ad-overlay-style {
  position: fixed;
  height: 50px;
  width: 100%;
  left: 0px;
  right: 0px;
  background: rgb(0, 0, 0);
  opacity: 1;
  z-index: 9999;
  bottom: 0px;
}

.reservable-container {
  height: 245px;
  .reservable-body {
  }
  .reservable-list {
    margin-top: 20px;
  }
  .reservable-list-items {
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
  }
  .reservable-list-item {
    flex: 0 0 170px;
    margin: 0 5px;
  }
  .reservable-blank {
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.select-max-txt {
  margin: 5px 0;
  color: $col-error;
  font-weight: bold;
  padding: 0 10px;
}
