@import 'styles/variables.scss';
.keyvisual-images {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background: $col-bg-gradient;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
  }
  .image-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 100%;
    min-width: $base-width;
    z-index: 0;
    opacity: 0;
  }
}
