@import 'styles/variables.scss';
.keyvisual-images {
  position: relative;
  width: 100%;
  height: 100%;
  .image-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: none;
    z-index: 0;
    opacity: 0;
  }
}
