@import 'styles/variables.scss';
.popular {
  display: block;
  width: 247px;
  height: 100%;
  border: 1px $item-border-color solid;
  border-radius: $border-radius;
  text-align: center;
  position: relative;
  overflow: hidden;
  &:hover {
    text-decoration: none;
    opacity: 0.7;
  }
}
.img-wrap {
  width: 245px;
  height: 163px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: #000;
}
.img {
  width: 245px;
  height: 163px;
  object-fit: cover;
}
.title {
  width: 100%;
  height: 24px;
  margin: 12px auto 4px;
  padding: 0 8px;
  color: $col-base;
  font-size: 1.6rem;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.location {
  width: 100%;
  height: 24px;
  padding: 0 8px;
  color: $col-base;
  font-size: 1.2rem;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.star-point {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  .text {
    margin-left: 10px;
  }
}
.campsite-item-rank {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  line-height: 32px;
  font-weight: bold;
  background: #fff;
  color: $col-base;
  &.is-one {
    background: #ffff00;
  }
  &.is-two {
    background: #ffff00;
  }
  &.is-three {
    background: #ffff00;
  }
  .rank-num {
    font-size: 1.6rem;
  }
  .rank-unit {
    font-size: 1rem;
  }
}
